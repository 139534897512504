// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/* Global SASS Variables */

// needs: @import "src/theme/variables.scss";

$beecloud-aqua: #00FFCC;
$beecloud-blue: #0C162A;
$beecloud-blue-transparent: rgba(12,22,42,0.25);
$beecloud-green: #78BC61;
$beecloud-orange: #edb142;
$beecloud-red: #EB4B24;
$beecloud-black: #4B4237;
$beecloud-dark-black: #262626;
$beecloud-box-shadow: 0 0px 10px #0000001f;
$beecloud-box-shadow-dark: 0 0px 10px #0000001f;
$beecloud-box-shadow-light: 3px 3px 10px lightgrey;
$beecloud-border-radius: 15px;

/** Ionic CSS Variables **/

:root {

  --ion-font-family: 'Open Sans', serif;

  /** primary **/
  --ion-color-primary: black;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #78BC61;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #a0d090;
  --ion-color-success-tint: #a0d090;

  /** warning **/
  --ion-color-warning: #edb142;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #f2c87a;
  --ion-color-warning-tint: #f2c87a;

  /** danger **/
  --ion-color-danger: #EB4B24;
  ;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/*
@media (prefers-color-scheme: dark) {

  body {
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    --ion-color-secondary: #0cd1e8;
    --ion-color-secondary-rgb: 12, 209, 232;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0bb8cc;
    --ion-color-secondary-tint: #24d6ea;

    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #78BC61;
    --ion-color-success-tint: #78BC61;

    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
  }

  .ios body {
    --ion-background-color: #fff;
    --ion-background-color-rgb: 255,255,255;

    --ion-text-color: #000000;
    --ion-text-color-rgb: 0,0,0;

    --ion-color-step-50: #f2f2f2;
    --ion-color-step-100: #e6e6e6;
    --ion-color-step-150: #d9d9d9;
    --ion-color-step-200: #cccccc;
    --ion-color-step-250: #bfbfbf;
    --ion-color-step-300: #b3b3b3;
    --ion-color-step-350: #a6a6a6;
    --ion-color-step-400: #999999;
    --ion-color-step-450: #8c8c8c;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #737373;
    --ion-color-step-600: #666666;
    --ion-color-step-650: #595959;
    --ion-color-step-700: #4d4d4d;
    --ion-color-step-750: #404040;
    --ion-color-step-800: #333333;
    --ion-color-step-850: #262626;
    --ion-color-step-900: #191919;
    --ion-color-step-950: #0d0d0d;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #fff;
    --ion-item-background-activated: #313131;
  }

  .md body {
    --ion-background-color: #fff;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #000000;
    --ion-text-color-rgb: 0,0,0;

    --ion-border-color: #222222;

    --ion-color-step-50: #f2f2f2;
    --ion-color-step-100: #e6e6e6;
    --ion-color-step-150: #d9d9d9;
    --ion-color-step-200: #cccccc;
    --ion-color-step-250: #bfbfbf;
    --ion-color-step-300: #b3b3b3;
    --ion-color-step-350: #a6a6a6;
    --ion-color-step-400: #999999;
    --ion-color-step-450: #8c8c8c;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #737373;
    --ion-color-step-600: #666666;
    --ion-color-step-650: #595959;
    --ion-color-step-700: #4d4d4d;
    --ion-color-step-750: #404040;
    --ion-color-step-800: #333333;
    --ion-color-step-850: #262626;
    --ion-color-step-900: #191919;
    --ion-color-step-950: #0d0d0d;

    --ion-item-background: #fff;
  }

  ion-title.title-large {
    --color: #000000;
  }
} */
