@import "src/theme/variables.scss";

._book-partner-appointment-desktop-component {
    padding: 20px;

    ._container {
		background-color: white;
		box-shadow: $beecloud-box-shadow-dark;
		border-radius: $beecloud-border-radius;
		margin-bottom: 20px;
	}

	._action {
		margin-top: 20px;
		padding: 30px;

		._action-container {

			._intro {
				margin-bottom: 30px;
			}

			._container-subtitle {
				font-size: 14px;
				margin-bottom: 3px;
			}

			&._location {

				._client-data-container {
					margin-top: 20px;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					
					&._nested-container {
						margin-top: 0;
					}
			
					._data {
						width: 33.33%;
						padding: 5px 10px;

						&._full {
							width: 100%;
						}
			
						&._half {
							width: 50%;
						}
			
					}
			
				}

			}

			&._talent-tags {

				._talent-tags-container {
					margin-bottom: 30px;

					._talent-tags {
						display: flex;
						flex-direction: row;
						align-items: center;
						border-radius: $beecloud-border-radius;
						padding: 20px;
						background-color: #F1F1F1;
    					border: 1px solid #E0E0E0;
						width: 100%;

						._talent-tag {
							display: flex;
							flex-direction: column;
							align-items: center;
							background-color: white;
							border-radius: $beecloud-border-radius;
							margin: 0 10px;
							padding: 10px;
							flex-grow: 1;
							border: 1px solid white;

							&:hover {
								cursor: pointer;
							}

							&._selected {
								border: 1px solid $beecloud-black;
								background-color: #f7f7f7;
							}

							ion-icon {
								font-size: 30px;
							}

							p {
								margin: 0;
								margin-top: 10px;
							}

						}

					}
					
				}

				._topic-packages-and-topics-container {
					margin-bottom: 30px;
					border-radius: $beecloud-border-radius;
					padding: 20px;
					background-color: #F1F1F1;
					border: 1px solid #E0E0E0;
					width: 100%;
					margin-bottom: 30px;

					._topic-packages-container {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						._topic-package-container {
							display: flex;
							flex-direction: column;
							align-items: center;
							width: 100%;
							margin: 0 10px;

							._topic-package {
								flex: 1 1 0px;
								display: flex;
								flex-direction: column;
								align-items: center;
								border-radius: $beecloud-border-radius;
								padding: 20px 30px 0px 30px;
								border: 1px solid #d8d8d8;
								background-color: white;
								width: 100%;
	
								&:hover {
									cursor: pointer;
									border: 1px solid $beecloud-black;
								}
	
								&._checked {
									border: 1px solid $beecloud-black;
									background-color: #f7f7f7;
								}
	
								p {
									margin: 0px;
									font-weight: bold;
								}
	
								img {
									width: 130px;
									margin: 10px 0;
									border-radius: 10px;
								}
	
							}
	
						}
	
					}

					._topics-container {
						background-color: white;
						border-radius: $beecloud-border-radius;
						margin: 20px 10px 0 10px;

						&:not(:empty) {
							border: 1px solid #d8d8d8;
							padding: 20px;
						}
						
						._topic-package-item {
							display: flex;
							flex-direction: row;
							align-items: center;
							margin: 10px 20px;

							ion-checkbox {
								margin-right: 10px;
							}

							._topic {
								flex-shrink: 10;

								ion-icon {
									margin-left: -3px;
									margin-bottom: 5px;
									font-size: 12px;

									&:hover {
										cursor: pointer;
									}

								}

								._topic-duration {
									font-size: 13px;
									color: grey;
									margin-left: 5px;
								}

							}

						}

						._add-topic {
							display: flex;
							flex-direction: row;
							align-items: center;
							margin: 10px 20px;

							&:hover {
								cursor: pointer;
							}
							
							ion-icon {
								font-size: 20px;
								margin-right: 10px;
								color: $beecloud-green;
							}
	
							span {
								flex-shrink: 10;
							}
						}

					}

					._problem-container {
						background-color: white;
						border-radius: $beecloud-border-radius;
						padding: 20px;
						border: 1px solid #d8d8d8;
						margin: 20px 10px 0 10px;
					}

				}

				

			}

			&._calendar-container {
				position: relative;

				._calendar-headers {
					position: absolute;
					right: 0;		
					top: 20px;
				}

				._calendar {
				
					mwl-calendar-week-view {
			
						.cal-week-view {
							box-shadow: none;
							border-radius: $beecloud-border-radius;
							border: 1px solid #d8d8d8;

							.cal-time-events .cal-event {
								white-space: pre-wrap;
							}

						}

					}
			
				}

			}

			&._confirmation {

				._confirmation-container {
					display: flex;
					flex-direction: row;
					border-radius: $beecloud-border-radius;
					border: 2px solid #d8d8d8;
					padding: 30px;
					margin: 30px 0;

					._map-container {
						width: 200px;
						margin-right: 30px;
						min-height: 200px;
						border: 1px solid #D3D3D3;
						border-radius: $beecloud-border-radius;
						overflow: hidden;
					}

					._consultant {
						width: 200px;
						margin-right: 30px;
	
						p {
							text-align: center;
							font-weight: bold;
							margin-bottom: 0;
						}
	
						._picture-container {
							width: 200px;
							height: 200px;
							border-radius: $beecloud-border-radius;
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							overflow: hidden;
	
							img {
								border-radius: $beecloud-border-radius;
							}
						}
	
					}

					._reservation-details,
					._appointment-details {

						._details {
							margin-top: 20px;
	
							._details-title {
								font-weight: bold;
								margin: 10px 0 5px 0;
							}
	
						}

						p {
							margin: 0;
						}

					}

				}

				._digicheques-container {
					border-radius: $beecloud-border-radius;
					border: 2px solid #d8d8d8;
					padding: 5px 15px;

					._add-digicheque-container {
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						margin: 10px 0;

						form-code {
							display: block;
							flex-grow: 10;
							margin-right: 10px;
						}

						._add-digicheque {
							background-color: $beecloud-green;
							margin-top: 21px;
						}

						p._spacer {
							margin: 27px 10px 0 10px;
						}


					}



					._codes-container {

						._code-title {
							color: gray;
							text-align: left;
							font-size: 14px;
							margin-bottom: 3px;
						}
				
						._code-container {
							background-color: #F1F1F1;
							border: 1px solid #E0E0E0;
							border-radius: $beecloud-border-radius;
							max-height: calc(100vh - 350px);
							overflow-y: auto;
							margin: 5px 0;
				
							&:empty {
								margin-bottom: 0;
							}
				
							._code {
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: space-between;
								padding: 10px;
					
								p {
									margin: 0;
									font-size: 14px;
					
									&._requirements,
									&._pricing,
									&._discount-code {
										font-size: 12px;
										color: grey;
									}
								}
					
								ion-icon {
									font-size: 25px;
									margin-right: 10px;
				
									&:hover {
										cursor: pointer;
									}
								}
				
							}
				
						}
				
					}

				}

				

				._outro {
					max-width: 800px;
					margin: 30px auto;
					color: #767676;
				}

			}

			._extra-info {
				margin-top: 20px;
				margin-bottom: 10px;
				border: 2px solid #d8d8d8;
				border-radius: $beecloud-border-radius;
				padding: 10px 20px;
				display: flex;
				flex-direction: row;
				align-items: center;
		
				ion-icon {
					font-size: 50px;
					margin-right: 30px;
				}
		
				._info-title {
					font-weight: bold;
				}
		
			}	

		}

		._buttons {
			margin-top: 20px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
	
			._button {
				min-width: 130px;
				margin: 0 5px;
	
				&._previous {
					background-color: $beecloud-orange;
				}  
	
				&._cancel {
					background-color: $beecloud-red;
					color: white;
					span {
						color: white;
					}
				}  
	
				&._next {
					background-color: $beecloud-green;
				} 
	
				&._finish {
					background-color: $beecloud-green;
					font-weight: bold;
				} 
	
				span {
					display: block;
					text-align: center;
					width: 100%;
					margin: 0;
				}
	
			} 
	
		}

	}

}