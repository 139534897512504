@import "src/theme/variables.scss";

._digipoints-desktop-page {
    padding: 20px;
	position: relative;

	._right-calendar-headers {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: absolute;
		top: 20px;
		right: 30px;

		._add-button {
			background-color: $beecloud-green;
			margin-right: 10px;
			font-weight: bold;
		} 
		
		._refresh-button {
			background-color: $beecloud-orange;
			color: white;
		}

	}

	._calendar-container {

		._calendar {
			height: calc(100vh - 180px);
			box-shadow: $beecloud-box-shadow-dark;
			border-radius: $beecloud-border-radius;
		
			mwl-calendar-week-view {
	
				.cal-week-view {
					border-radius: $beecloud-border-radius;
					overflow-y: hidden;

					.cal-time-events .cal-event {
						white-space: pre-wrap;
					}

				}

			}
	
		}

	}
	

}