@import "src/theme/variables.scss";

._calendar-mobile-component {
	height: 100%;

	._mobile-header-segment ::ng-deep ._segment-mobile-component {
        background-color: #FFBC5C; 
    }

	._segmented-component {
		height: calc(100% - 50px);
		margin-top: 0;

		&._list-view {
			._no-data-container {
				background-color: white;
				padding: 10px 20px;
				border-radius: $beecloud-border-radius;
				box-shadow: $beecloud-box-shadow-dark;
				margin: 20px auto;
				width: 90%;
			}
		
			._list-date-divider {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 20px;
				margin-top: 20px;
		
				._line {
					border-top: 1px solid lightgray;
					flex-grow: 10;
				}
		
				._date {
					margin: 0 20px;
					color: grey;
				}
		
			}
		
			._appointment-card {
				border-radius: $beecloud-border-radius;
				margin-top: 0;
				background-color: white;
				box-shadow: $beecloud-box-shadow-dark;
		
				&._unfinished {
					border: 1px solid $beecloud-red;
				}
		
				ion-card-content {
					padding: 0;
		
					._intro,
					._info {
						width: 100%;
						padding: 20px 20px 0 20px;
		
						p {
							word-break: break-word;
						}
					}
		
					._contract-issue{
						width: calc(100% - 40px);
						padding: 20px;
						margin: 0 20px;
						background-color: rgba(235, 75, 36, 0.2);
						border: 2px solid #EB4B24;
						border-radius:15px;
						color:black;
						margin-bottom:20px;
		
						b{
							text-transform:uppercase;
						}
					}
		
					._info {
						padding-top:0px;
						margin-bottom: 20px;
						border-bottom: 1px solid #F5F5F5;
					}
		
					._intro-date {
						font-weight: 450;
						font-size: 20px;
						color: black;
						text-align: center;
						padding:10px 20px;
						border-bottom: 1px solid #F5F5F5;
						&::first-letter{
							text-transform: uppercase;
						}
		
						span{
							display: block;
							font-size:18px;
						}
					}
		
					._details {
						color: grey;
					}
		
					._isMember {
						font-weight: bold;
					}
		
					._review-score {
						margin-left: 20px;
		
						ion-icon {
							color: #F7F157;
							margin-right: 3px;
		
							&:last-child {
								margin-right: 0;
							}
						}
					}
		
					._buttons {
						padding: 0px 20px 20px 20px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						gap:20px;
		
						&._close-buttons {
							._button {
								&._is-today {
									background-color: $beecloud-green;
		
									span{
										color:white;
									}
									ion-icon {
										color:white;
									}
								}
							}
						}
		
						._button {
							padding: 10px;
							flex-grow: 1;
							width: 100%;
		
							span {
								margin-right: 10px;
								flex-grow: 1;
							}
						}
		
					}
				}
			}
		}

		&._calendar-view {
			
		}
	}

}
