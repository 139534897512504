@import "src/theme/variables.scss";

:host {
	height: 100%;
	padding: 20px;
	background-color: white;
	max-width: 630px;
}

._create-digipoint-component {
	height: 100%;

    ._subtitle {
        text-align: left;
    }

	._step {
		padding: 20px 0;
	}

    ._step-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 5px;

        ._step-indicator {
            background-color: $beecloud-orange;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
        }

        ._step-description {
            margin: 0;
            margin-left: 10px;
            font-weight: 450;
            flex-shrink: 1;
        }

        ._step-subtitle {
            margin-left: 10px;
        }

    }

	._recurrence-container p.label span,
	._name-container span {
		display: none;
	}

	._name-container,
    ._consultants-container,
	._recurrence-container,
    ._input-container,
    ._type-container,
    ._contact-container,
    ._digipoint-interval-container {
        margin-top: 10px;
    	margin-left: 30px;
		width: 70%;
	}

    ._digipoint-interval-container {
        ._digipoint-interval {
            margin: 10px;
            border-radius: 10px;
            background-color: #F1F1F1;
            padding: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 20px);

            p {
                font-size: 14px;
                margin: 0;
            }

            ion-icon {
                font-size: 20px;
                margin-right: 10px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        ._add-interval {
			justify-content: flex-start;

            &:hover {
                cursor: pointer;
            }

            ion-icon {
                color: $beecloud-green;
            }

        }
    }

    ._type-container {
        form-number {
            display: block;
            margin-top: 20px;
        }
    }

    ._time-input-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        ._time-input-divider {
            margin: 0 5px;
        }

        form-time {
            display: block;
            flex-grow: 10;
        }
    }

    ._custom-interval {
        margin-bottom: 20px;
        margin-left: 5px;

        ._interval-inputs {
            display: flex;
            align-items: center;
            flex-direction: row;

            p, input {
                margin-right: 10px;
            }

            input {
                background-color: #f1f1f1;
                border: none;
                padding: 6px 10px;
                height: 36px;
                font-size: 14px;
                color: #000;
                width: 50px;
                text-align: center;
                border-radius: 5px;
            }

            form-validation-error {
                display: none;
            }

            form-select {
                display: block;
                flex-grow: 10;
                margin: 0;
                margin-top: -3px;

                .ng-select-container {
                    border: none !important;
                    border-radius: 5px !important;
                }
            }
        }
    }

	._address-container {
		margin-left: 20px;
	}

    ._contact-container {

        form-input {
            display: block;
            margin-bottom: 20px;
        }
	}

}

._buttons-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding-top: 30px;
	padding-bottom: 20px;

	._add-button {
		background-color: $beecloud-green;
		font-weight: 500;
	}

}
