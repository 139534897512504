@import "src/theme/variables.scss";

._calendar-desktop-component {
    padding: 20px;
    position: relative;

    ._left-calendar-buttons {
        display: flex;
        flex-direction: row;
        margin-top: 0;
        margin-bottom: 10px;
        gap: 0 10px;

        ._add-button {
            background-color: $beecloud-green;
            font-weight: bold;
        }

        ._create-closing-day-button {
            background-color: $beecloud-red;
            font-weight: bold;
            color: white;
        }

        ._refresh-button {
            background-color: $beecloud-orange;
            color: white;
        }

    }

    ._calendar-filters {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        zipcode-filter-component,
        select-filter-component,
        consultant-filter-component {
            min-height: 45px;

            ::ng-deep ._filter-component {
                margin-right: 0;
            }
        }

        ._filter-name {
            color: grey;
            margin: 5px 0;
            text-transform: uppercase;
        }

        ._addressDiv {
            background-color: white;
            border: 1px solid #D3D3D3;
            border-radius: 12px;
            margin-right: 20px;
            width: fit-content;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 15px;
            position: relative;
            min-height: 45px;

            ._address {
                background-color: #F1F1F1;
                border: 1px solid #E0E0E0;
                padding: 6px 20px;
                border-radius: 10px;
                font-size: 14px;
                color: black;
                width: 90%;
                margin: 5px -17px 5px 10px;
            }

            ._closeAddr {
                height: 14px;
                margin-left: -5px;
                margin-top: -2px;
            }

            ion-icon:hover {
                cursor: pointer;
            }
        }


    }

    calendar-desktop-component ::ng-deep {
        ._navigation-buttons,
        ._view-selection {
            margin-right: 10px !important;
        }

        ._navigate-to-date {
            min-height: 45px;
        }
    }

    ._calendar {
        height: calc(100vh - 184px);

        &._has-filters {
            height: calc(100vh - 274px);
        }
    

        mwl-calendar-month-view {

            .cal-month-view {
                border-radius: $beecloud-border-radius;
                box-shadow: $beecloud-box-shadow-dark;
            }

        }

        mwl-calendar-week-view {

            .cal-week-view {
                border-radius: $beecloud-border-radius;
                box-shadow: $beecloud-box-shadow-dark;
            }

        }

        ._event-icon {
            height: 20px;
            width: 20px;
        }

    }

}