@import "src/theme/variables.scss";

:host {
	height: 100%;
	background-color: white;
}

.owl-dt-popup-container {

    .owl-dt-calendar-cell-selected {
        background-color: $beecloud-green;
    }
}

._create-workblock-component {
	height: 100%;

	._container{
		padding:20px;

		._step {
			padding: 20px 0;
		}
	
		._step-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-bottom: 5px;
	
			._step-indicator {
				background-color: $beecloud-orange;
				width: 20px;
				height: 20px;
				border-radius: 100%;
				font-size: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
			}
	
			._step-description {
				margin: 0;
				margin-left: 10px;
				font-weight: 450;
				flex-shrink: 1;
			}
	
		}
	
		._input-container {
			margin-top: 10px;
			margin-left: 30px;
		}
	
		._time-input-container {
			display: flex;
			flex-direction: row;
			align-items: center;
	
			._time-input-divider {
				margin: 0 5px;
			}
	
			form-time {
				display: block;
				flex-grow: 10;
			}
		}
		
		._recurring-container {
			width: 170px;
			margin-left: 30px;
	
			._active {
				font-weight: 450;
			}
	
			ion-toggle {
				margin: 0 10px;
			}
	
		}
		
		._regions-container,
		._consultant-container {
			margin-left: 30px;
		}
	
		._consultant-container {
	
			._selected-consultant-container {
				border: 2px solid #f0f0f0;
				border-radius: $beecloud-border-radius;
				margin-top: 20px;
	
				._selected-consultant {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					padding: 10px;
		
					p {
						margin: 0;
		
						&._consultant {
							font-size: 13px;
						}
					}
		
					ion-icon {
						font-size: 25px;
						margin-right: 10px;
	
						&:hover {
							cursor: pointer;
						}
					}
	
				}
	
			}
		}
	
		._buttons-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			padding-top: 30px;
			padding-bottom: 20px;
		
			._add-button {
				background-color: $beecloud-green;
				font-weight: 500;
			}		
		}		
	}
}

