/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Required CSS for Angular Calendar Module */
@import "../node_modules/@beego/ngx-calendar/assets/calendar.css";
@import "./app/modules/internal-shared/gui/components/calendar/calendar-component/desktop/calendar.desktop.component.scss";
@import "./app/modules/internal-shared/gui/components/calendar/calendar-component/mobile/calendar.mobile.component.scss";
@import "./app/modules/partner/gui/components/appointment-components/book-appointment-component/desktop/book-appointment.desktop.component.scss";
@import "./app/modules/partner/gui/components/appointment-components/book-appointment-component/mobile/book-appointment.mobile.component.scss";
@import "./app/modules/partner/gui/pages/digipoints-page/desktop/digipoints.desktop.page.scss";
@import "./app/modules/partner/gui/pages/digipoints-page/mobile/digipoints.mobile.page.scss";

/* Required CSS for select component & date picker */
@import "../node_modules/@beego/ngx-form-components/assets/form-components.css";
@import "./app/modules/internal-shared/gui/components/calendar/create-workblock-component/create-workblock.component.scss";
@import "./app/modules/internal-shared/gui/components/calendar/edit-workblock-component/edit-workblock.component.scss";
@import "./app/modules/partner/gui/components/digipoint-components/create-digipoint-component/create-digipoint.component.scss";

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Global style */
@import "src/theme/variables.scss";
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

html,
body,
table {
	font-family: 'Open Sans', serif !important;
}

html {
	padding-top: constant(safe-area-inset-top) !important;
	padding-top: env(safe-area-inset-top) !important;
	padding-bottom: calc(constant(safe-area-inset-bottom)) !important;
	padding-bottom: calc(env(safe-area-inset-bottom)) !important;
}

body {
	position: relative;
}

.flex-grow {
	display: flex;
	flex: 1;
	flex-direction: column;
	min-height: 0;
}

._title {
	font-size: 20px;
	font-weight: 500;
	color: $beecloud-dark-black;
	margin: 0;
}

._small-title {
	font-size: 17px;
	color: $beecloud-dark-black;
	margin: 0;
}

._subtitle {
	font-size: 13px;
	color: grey;
	margin: 0;
}

._button {
	padding: 10px 15px;
	border-radius: 10px;
	box-shadow: 3px 3px 10px lightgrey;
	text-align: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	background-color: lightgrey;
	color: black;

	&:active {
		opacity: 0.9;
		box-shadow: none;
	}

	&:hover {
		cursor: pointer;
	}

	&[disabled] {
		background-color: lightgrey;

		&:hover {
			cursor: not-allowed;
		}
	}

	&._delete-button {
		background-color: $beecloud-red;
		color: white;

		span,
		ion-icon {
			color: white !important;
		}
	}

	&._export-button,
	&._save-button,
	&._edit-button,
	&._add-button,
	&._update-button,
	&._create-button,
	&._upload-button,
	&._change-button,
	&._print-button,
	&._info-button {
		background-color: $beecloud-green !important;
		color: white !important;

		span,
		ion-icon {
			color: white !important;
		}
	}

	ion-icon {
		color: black;
		font-size: 20px;
		flex-shrink: 0;
	}

	span {
		margin-left: 10px;
		margin-right: 15px;
		flex-shrink: 10;
		color: black;
	}
}

* {
	outline: none;
	user-select: text;

	&:focus {
		outline: none;
	}
}

@media only screen and (min-width: 1024px) {

	// adds spacer to scoll on desktop page (add pages that need it)
	profile-page {
		margin-top: 20px;
	}
}

._navigatable {

	&:hover {
		cursor: pointer;
		opacity: 0.9;
	}

}

._option-container {
	display: flex;
	flex-direction: row;
	align-items: center;

	ion-icon {
		margin-right: 8px;
	}
}

/* could be moved to the info-line component */
info-line {
	p {
		padding-left: 15px;

		ol,
		ul {
			margin: 0px;
			padding-left: 16px;
		}
	}
}




/**
 * Global styling for topic because when drag-drop is used, draging needs global styling
 * because of view encapsulation
 */
.cdk-drag._topic {
	margin: 0;
	color: $beecloud-dark-black;
	border-radius: $beecloud-border-radius;
	background-color: white;
	box-shadow: $beecloud-box-shadow-dark;
	padding: 10px;

	&:hover {
		cursor: move;
		cursor: grab;
	}

	&:active {
		cursor: move;
		cursor: grabbing;
	}

	._topic-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 5px 0;

		ion-icon {
			font-size: 18px;

			&:hover {
				cursor: pointer;
			}

		}

		._topic-title {
			margin: 0;
			color: $beecloud-dark-black;
			flex-shrink: 10;

			._topic-duration {
				font-size: 13px;
				color: grey;
			}

		}

	}

	._topic-duration {
		font-size: 13px;
		color: grey;
	}

}

segment-desktop-component {
	left: 0px;
	border-radius: 25px;
}

._segmented-component {
	margin-top: 50px;

	table-desktop-component ._head:not(._embedded) {
		top: 75px !important;
		padding-top: 0 !important;
		margin-top: 0 !important;
	}
}

segment-mobile-component._mobile-header-segment {
	._segment-mobile-component {
		margin-bottom: 0 !important;
		box-shadow: 5px 5px 10px -10px black;

		._segment-container {
			width: 100% !important;
			margin-top: -1px !important;
			margin-left:0px !important;
			margin-right:0px !important;
			border-radius: 0 !important;
			border: none !important;
			padding-top: 10px !important;
			background-color: transparent !important;

			@media only screen and (max-width: 450px) {
				margin: 0 !important;
				margin-top: -1px !important;
				width: 100% !important;
			}

			._segment {
				padding: 5px 0px !important;
				margin: 0 !important;
				border-radius: 0 !important;

				&._active {
					padding-bottom: 0px !important;
				}

				._segment-name {
					color: white !important;
					font-size: 17px !important;
					padding: 0 10px !important;
					border-bottom: 5px solid transparent !important;

					@media only screen and (max-width: 450px) {
						font-size: 15px !important;
						padding: 0 5px !important;
					}
				}

				&._active ._segment-name {
					padding-bottom: 5px !important;
					font-size: 17px !important;
					font-weight: bold !important;
					border-bottom: 5px solid white !important;

					@media only screen and (max-width: 450px) {
						font-size: 15px !important;
					}
				}

			}

		}

	}
}


filter-component {
	.ng-select {
		max-width: inherit;

		.ng-dropdown-panel {
			width: auto;
			min-width: 100%;
		}
	}
}


ng-select {

	.ng-select-container {

		.ng-value-container {

			.ng-input {
				width: 50px !important;
			}
		}
	}

}



/* Print rules */

@media print {
	body.backdrop-no-scroll {
		overflow: visible;
	}
}

/* Ionic component overrides */

ion-select:not(.legacy-select) {
	min-height: inherit;
}

ion-select {
	--highlight-color: inherit;
}

ion-content {
	--ion-background-color: transparent;
}

ion-popover {
	--width: fit-content !important;
	--max-width: 95%;

	&:not(.ios, [custom="_no-center"], .no-center)::part(content) {
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
	}

	&.ios {
		height: 100vh;

		&::part(content) {
			left: 2.5%;
			--offset-y: -100px;
		}
	}

}

.plt-mobile ion-popover {
	--width: 95% !important;
}

ion-loading._loading-indicator {
	--background: white;
	--spinner-color: #4B4237;
	color: #4B4237;

	.loading-wrapper {
		border-radius: 10px;

		.loading-content {
			font-size: 16px;
		}

	}

}

ion-searchbar {

	.searchbar-input-container {
		border: 2px solid #f0f0f0;
		border-radius: $beecloud-border-radius;
		--box-shadow: none;

		input {
			border-radius: $beecloud-border-radius !important;
		}
	}

}


ion-modal {

	&.auto-height {

		&.bottom {
			align-items: flex-end;
		}

		--height: auto;

		@media print {
			--height: 100%;
		}

		@media only screen and (max-width: 1024px) {
			--height: 100%;
		}

		.ion-page {
			position: relative;
			display: block;
			contain: content;
			height: 100%;

			.inner-content {
				max-height: 90vh;
				overflow: auto;

				@media only screen and (max-width: 1024px) {
					max-height: 100%;
					height: 100%;
				}

				@media print {
					overflow: visible;
				}
			}
		}

		&.mobile-only {
			--height: 100%;
			--width: 100%;
			--max-width: 1024px;

			.ion-page {

				.inner-content {
					max-height: 100%;
					height: 100%;
				}
			}
		}
	}

	>* {

		@media only screen and (min-width: 1024px) {
			border-radius: 7px !important;
		}

	}

}

ion-alert {
	._alert-button {
		color: black;
	}
}

ion-toast {

	&._saved-toast {
		--background: #78BC61;
		--border-radius: 10px;
		--width: fit-content;
		--color: black;
		--ion-safe-area-bottom: 20px;
		--max-width: 90%;
		font-weight: 500 !important;
	}

	&._error-toast {
		--background: #EB4B24;
		--border-radius: 10px;
		--width: fit-content;
		--color: white;
		--ion-safe-area-bottom: 20px;
		--max-width: 90%;
		--button-color: white;
		font-weight: 500 !important;

		.toast-wrapper {
			max-width: 90%;
		}

	}

}

.plt-mobile.plt-mobileweb ion-toast {

	&._saved-toast {
		--max-width: 90%;
	}

	&._error-toast {
		--max-width: 90%;
	}
}

ion-grid {
	--ion-grid-columns: 15;
}

._municipality ion-checkbox.checkbox-disabled,
._payment-status ion-checkbox.checkbox-disabled,
._topic-container ion-checkbox.checkbox-disabled,
._status ion-checkbox.checkbox-disabled {
	opacity: 1;
}




rating-component {
	&.ng-invalid {
		._label {
			._required {
				display: inline !important;
			}
		}
	}
}

ion-backdrop.ios {
	height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 26px);
	height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 26px);
}
