@import "src/theme/variables.scss";

._digipoints-mobile-page {
    padding: 20px;
	padding-top: 90px;

    ._title {
        color: $beecloud-dark-black;
        margin-bottom: 10px;
    }

}