@import "src/theme/variables.scss";

:host {
	height: 100%;
	background-color: white;
}

.owl-dt-popup-container {

    .owl-dt-calendar-cell-selected {
        background-color: $beecloud-green;
    }
}
._edit-workblock-component {
    height: 100%;

    ._container{
        padding:20px;

        ._step {
            padding: 20px 0;

            &:first-of-type {
                padding-top: 0;
            }
        }
    
        ._step-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 15px 0 5px 0;
    
            ._step-indicator {
                background-color: $beecloud-orange;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
            }
    
            ._step-description {
                margin: 0;
                margin-left: 10px;
                font-weight: 450;
                flex-shrink: 1;
            }
    
        }
    
        ._input-container {
            margin-top: 10px;
            margin-left: 30px;
        }
    
        ._time-input-container {
            display: flex;
            flex-direction: row;
            align-items: center;
    
            ._time-input-divider {
                margin: 0 5px;
            }
    
            form-time {
                display: block;
                flex-grow: 10;
            }
        }
        
        ._recurring-container {
            width: 170px;
            margin-left: 30px;
    
            ._active {
                font-weight: 450;
            }
    
            ion-toggle {
                margin: 0 10px;
            }
    
        }
        
        ._regions-container {
            margin-left: 30px;
        }    

    }

 

}

._buttons-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding-top: 30px;

	._delete-button {
		background-color: $beecloud-red;
		color: white;
		margin-right: 20px;
	}
	
	._save-button {
		background-color: $beecloud-green;
		font-weight: 500;
	}

}